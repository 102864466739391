import { computed } from '@angular/core';
import { IRabbinatePicture } from '@app/core/entities/rabbinates.interface';
import { patchState, signalStore, withComputed, withMethods } from '@ngrx/signals';
import { setEntities, withEntities } from '@ngrx/signals/entities';

export const RabbinateRepository = signalStore(
  { providedIn: 'root' },
  withEntities<IRabbinatePicture>(),
  withComputed((store) => ({
    count: computed(() => store.entities().length),
  })),
  withMethods((store) => ({
    setRabbinate(rabbinate: IRabbinatePicture[]) {
      patchState(store, setEntities(rabbinate));
    },
    getRabbinateUrlByName(name: string) {
      return (
        store.entities().find((item) => item.attributes.Name === name)?.attributes.Pictiure.data.attributes.url || ''
      );
    },
  })),
);
