import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { API_FILTER_PRODUCTS, API_PRODUCT, API_PRODUCT_FAVORITE } from '@app/constants/api-path.constant';
import { DISCOUNT_DEPARTMENT_ID_FOR_SALES } from '@app/constants/constants';
import { ParamsForProducts } from '@app/core/entities/paramsForProducts';
import { ProductsSearch } from '@app/core/entities/product';
import { Product } from '@app/core/entities/product.interface';
import { IRabbinatePicture } from '@app/core/entities/rabbinates.interface';
import { RabbinateRepository } from '@app/state/rabbinate.repository';
import { environment } from '@env/environment';
import { SpecialDepartment } from '@services/department-list.service';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { RabbinateService } from './rabbinate.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private readonly rabbinateRepo = inject(RabbinateRepository);

  constructor(
    private readonly http: HttpClient,
    private readonly rabbinateService: RabbinateService,
  ) {}

  getProductsByParams(params: ParamsForProducts): Observable<ProductsSearch> {
    Object.keys(params).forEach((key) =>
      !params[key as keyof ParamsForProducts] ? delete params[key as keyof ParamsForProducts] : {},
    );
    const p = new HttpParams({ fromObject: { ...params } });
    return this.http.get<ProductsSearch>(environment.baseUrl + API_FILTER_PRODUCTS, { params: p });
  }

  getProductsByDepartment(id: number): Observable<Product[]> {
    return this.http
      .get<ProductsSearch>(environment.baseUrl + API_FILTER_PRODUCTS, {
        params: {
          departmentIds: [id],
        },
      })
      .pipe(map((s) => s.items));
  }

  getProductsByDepartmentWithPages(id: number, limit: number, page: number): Observable<ProductsSearch> {
    return this.http.get<ProductsSearch>(environment.baseUrl + API_FILTER_PRODUCTS, {
      params: {
        departmentIds: [id],
        limit,
        page,
      },
    });
  }

  getProduct(id: number | string): Observable<Product> {
    return this.http.get<Product>(environment.baseUrl + API_PRODUCT + '/' + id);
  }

  getSpecialProducts(name: SpecialDepartment) {
    return this.http.get<Product[]>(environment.baseUrl + API_PRODUCT + '/' + name);
  }

  getRabbinates(): Observable<IRabbinatePicture[]> {
    return this.rabbinateService.list().pipe(
      map((res: IRabbinatePicture[]) => {
        this.rabbinateRepo.setRabbinate(res);
        return res;
      }),
    );
  }

  makeFavorite(id: number): Observable<{}> {
    return this.http.post(environment.baseUrl + API_PRODUCT_FAVORITE(id), {});
  }

  deleteFavorite(id: number): Observable<{}> {
    return this.http.delete(environment.baseUrl + API_PRODUCT_FAVORITE(id), {});
  }
}

export const useProduct = () => inject(ProductService);

export const getSalesProducts = () => {
  const isLoading = signal(true);
  const products$ = useProduct()
    .getProductsByDepartment(DISCOUNT_DEPARTMENT_ID_FOR_SALES)
    .pipe(
      catchError(() => of([])),
      tap(() => isLoading.set(false)),
    );

  return Object.assign(
    toSignal(products$, {
      initialValue: [] as Product[],
    }),
    {
      isLoading,
    },
  );
};
