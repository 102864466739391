import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_RABBINATE } from '@app/constants/api-path.constant';
import { IRabbinate } from '@app/core/entities/rabbinate.interface';
import { IRabbinatePicture } from '@app/core/entities/rabbinates.interface';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RabbinateService {
  constructor(private readonly httpClient: HttpClient) {}

  get(departmentId: number): Observable<IRabbinate[]> {
    return this.httpClient.get<IRabbinate[]>(`${environment.baseUrl}${API_RABBINATE}`, {
      params: {
        departmentId,
      },
    });
  }

  list(): Observable<IRabbinatePicture[]> {
    return this.httpClient.get<IRabbinatePicture[]>(`${environment.baseUrl}${API_RABBINATE}`);
  }
}
