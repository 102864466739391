import { inject, InjectionToken } from '@angular/core';
import { Product } from '@app/core/entities/product.interface';

/**
 * Cache for product items.
 * is used to take a product without creating a request to the backend
 */
export const PRODUCTS_MAP = new InjectionToken<Map<number | string, Product>>('products', {
  providedIn: 'root',
  factory: () => new Map<number | string, Product>(),
});

export const getProductsMap = () => inject(PRODUCTS_MAP);


/**
 * Cache for product pictures.
 * is used to take a product without creating a request to the backend
 */
export const PRODUCTS_MAP_IMAGES = new InjectionToken<Map<number | string, HTMLImageElement>>('products', {
  providedIn: 'root',
  factory: () => new Map<number | string, HTMLImageElement>(),
});

export const getProductsMapImages = () => inject(PRODUCTS_MAP_IMAGES);
